import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { selectVariableData } from '../../store/page/selectors';
import merge from 'lodash.merge';
import { combineClassNames, replacePlaceholdersWithVariablesV2, handleOpenUrl, getSizesBasedOnScreen, executeFunction, parseIfJSON, getNestedValue } from '../../utils/base';
import IconSvg from '../../../assets/icons/x-close.svg';
import { useDispatch, useSelector } from 'react-redux';
import { SelectComponent, MultiSelectComponent, StyleUpdateChunk, RequestUpdateChunk, FunctionUpdateChunk, Breakpoints } from '../../types/base';
import { submitFormRequest, mergeStyleRequest, pageRequest, formValuesRequest, updateVariableRequest } from '../../store/page/actions';
import generateComponentStyles from '../../utils/styleHelper';

// import CheckIcon from '../../../assets/icons/components/check.svg';
import ChevronDown from '../../../assets/icons/components/chevron-down.svg';

import * as styles from './Select.module.scss';

const SelectWrapper = styled.div`
  z-index: 1;
  position: relative;
  display: grid;
  ${(props) => generateComponentStyles(props.$shape)}
`;

const SelectButton = styled.button<{ isOpen: boolean }>`
  display: grid;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  padding: 0;
  background-color: transparent;
  ${(props) => generateComponentStyles(props.$shape, props.$baseScreenResolution, props.$skipStates)}
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  &:focus, &:focus-within {
    outline: none;
    box-shadow: ${(props) => (props.isOpen ? 'none' : 'none')};
  }
`;

const SelectInput = styled.input`
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    width: 100%;
    min-width: 80px;
    ${(props) => generateComponentStyles(props.$shape)}
`;

const SelectOptions = styled.div`
  position: fixed;
  top: ${(props) => `${(props.top + props.height + 4) || 0}px`};
  left: ${(props) => `${props.left || 0}px`};
  width: ${(props) => `${props.width || 0}px`};
  max-height: calc(7 * 2.5em);
  overflow-y: auto;
  background: #fff;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  z-index: 999999999999;
  ${(props) => generateComponentStyles(props.$shape)}

  /* Custom Scrollbar for Webkit browsers */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--Colors-Background-bg-quaternary, #EAECF0);
    border-radius: var(--radius-full, 9999px);
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Custom Scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--Colors-Background-bg-quaternary, #EAECF0) transparent;
`;

const SelectOption = styled.div<{ isSelected: boolean }>`
  padding: 10px;
  cursor: pointer;
  background: ${(props) => (props.isSelected ? '#F9FAFB' : '#FFF')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  &:hover {
    background: #F9FAFB;
  }
`;

interface SelectComponentProps extends SelectComponent {
  dataLake?: any;
  nestedTable?: boolean;
  parentIndex?: number;
  styleChunks?: any;
  parentRef?: any;
}

interface MultiSelectComponentProps extends MultiSelectComponent {
  dataLake?: any;
  nestedTable?: boolean;
  parentIndex?: number;
  styleChunks?: any;
  parentRef?: any;
}

const CombinedSelect: React.FC<SelectComponentProps & { pageSettings: any } | MultiSelectComponentProps & { pageSettings: any }> = ({
  id, type, options: optionsDraft, style: stDraft, value: valueDraft, events: eventsDraft, soc, placeholder, settings,
  pageSettings, dataLake, activeState, nestedTable, parentIndex, styleChunks, parentRef
}) => {
  const { delay = 500 } = settings || {};
  const { style: styleDraft, events, options: optionsUnprepared, value } = dataLake?.data ? replacePlaceholdersWithVariablesV2({ style: stDraft, events: eventsDraft, value: valueDraft, options: optionsDraft }, dataLake.data) : { style: stDraft, events: eventsDraft, value: valueDraft, options: optionsDraft };
  const mergeStylesWithChunks = useCallback((style: any, chunks: any, index: number) => {
    if (chunks && chunks[id] && chunks[id][index] && typeof index === 'number') {
      return merge({}, style, chunks[id][index]);
    }
    return style;
  }, [id]);
  const variableData = useSelector(selectVariableData);
  const options = parseIfJSON(optionsUnprepared);  
  // First useMemo to merge active state styles with base style
  const mergedStyleWithState = useMemo(() => {
    let mergedStyle = styleDraft;
    if (activeState && styleDraft?.states?.[activeState]) {
      mergedStyle = merge({}, styleDraft, styleDraft.states[activeState]);
    }

    // Handle states in breakpoints
    if (styleDraft?.breakpoints) {
      mergedStyle.breakpoints = Object.entries(styleDraft.breakpoints).reduce((acc, [breakpoint, breakpointStyle]) => {
        const typedBreakpointStyle = breakpointStyle as Breakpoints;
        if (typedBreakpointStyle?.states?.[activeState]) {
          acc[breakpoint] = merge({}, typedBreakpointStyle, typedBreakpointStyle.states[activeState]);
        } else {
          acc[breakpoint] = typedBreakpointStyle;
        }
        return acc;
      }, {} as typeof styleDraft.breakpoints);
    }

    return mergedStyle;
  }, [styleDraft, activeState]);

  // Second useMemo to merge styles with chunks based on parentIndex
  const style = useMemo(() => {
    return mergeStylesWithChunks(mergedStyleWithState, styleChunks, parentIndex);
  }, [mergedStyleWithState, styleChunks, parentIndex]);
  const baseScreenResolution =  pageSettings?.baseScreenResolution ? getSizesBasedOnScreen(pageSettings?.baseScreenResolution) : undefined;
  const { grid: gridStyle, border, font, placeholder: placeholderStyle, ...otherStyle } = style || {};
  const { width, height, flex: flexStyle } = style || {};
  const { grow, shrink, basis } = flexStyle || {};
  const [isHovered, setIsHovered] = useState(false);
  const selectRef = useRef(null);
  const dropdownRef = useRef(null);

  const [position, setPosition] = useState({ top: 0, left: 0, width: 0, height: 0 });

  const isSearchEnabled = settings?.search;
  const isMultiple = type === 'multiple';
  const dispatch = useDispatch();

  const internalFns = {
    $setVariable: (key: string, value: any) => {
      dispatch(updateVariableRequest({ variable: key, value }));
    },
    $getVariable: (key: string) => {
      return getNestedValue(variableData, key);
    }
  };
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [internalValue, setInternalValue] = useState<string[]>(Array.isArray(value) ? value : [value]);
  const {
    on_change: changeStyles,
    on_launch: launchUpdates,
    on_hover: hoverUpdates,
    on_unhover: unhoverUpdates,
    on_focus: focusUpdates,
    on_blur: blurUpdates,
    on_outclick: outclickUpdates,
  } = events || {};

  useEffect(() => {
    if ((Array.isArray(value) && value.length) || (value && typeof value === 'string')) {
      setInternalValue(Array.isArray(value) ? value : value ? [value] : []);
    }
  }, [value]);

  const updatePosition = () => {
    if (parentRef && parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect();
      setPosition({ top: rect.top, height: rect.height, left: rect.left, width: rect.width });
    } else if (selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      setPosition({ top: rect.top, height: rect.height, left: rect.left, width: rect.width });
    }
  };

  useEffect(() => {
    updatePosition(); // Update position when isOpen changes
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition, true); // true to capture event in the capturing phase
    updatePosition(); // Initial position update

    return () => {
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition, true);
    };
  }, [parentRef]);

  useEffect(() => {
    if (Array.isArray(value) && value.length && value.join(' ') !== internalValue?.join(' ')) {
      setInternalValue(value);
    } else if (!Array.isArray(value) && value !== internalValue[0]) {
      setInternalValue([value]);
    }
  }, [value]);

  const handleHover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (hoverUpdates) {
      const styleUpdates = hoverUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = hoverUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = hoverUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  const handleUnhover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (unhoverUpdates) {
      const styleUpdates = unhoverUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = unhoverUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = unhoverUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  useEffect(() => {
    let clickInsidePortal = false;

    const handleMouseDown = (event) => {
      if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
        clickInsidePortal = true;
      }
    };

    const handleClickOutside = (event) => {
      if (!clickInsidePortal && selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
      clickInsidePortal = false; // Reset the flag
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('click', handleClickOutside);
      };
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && focusUpdates) {
      const styleUpdates = focusUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = focusUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = focusUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, undefined, internalFns);
          }
        });
      }
    } else if (!isOpen && blurUpdates) {
      const styleUpdates = blurUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = blurUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = blurUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, undefined, internalFns);
          }
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (launchUpdates && launchUpdates.length) {
      const styleUpdates = launchUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = launchUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = launchUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            // const newTimeout = setTimeout(() => {
            //   dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
            // }, config?.delay * 1000 || 0);

            // timeoutsRef.current.push(newTimeout);
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, undefined, internalFns);
          }
        });
      }
    }

    return () => {
      timeoutsRef?.current?.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, [launchUpdates]);

  const handleChange = (e: React.MouseEvent<HTMLDivElement>, selected: string) => {
    if (type === 'multiple') {
      setInternalValue(prevValues => {
        const newValues = [...prevValues, selected];
        return newValues.filter((value, index) => newValues.indexOf(value) === index);
      });
      setSearchTerm('');
    }
    // If single, just replace
    else {
      setInternalValue([selected]);
      setSearchTerm('');
    }
    dispatch(formValuesRequest({ field: { id, value: selected } }));
    if (changeStyles) {
      const styleUpdates = changeStyles.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = changeStyles.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = changeStyles.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'command') {
            dispatch(pageRequest({ location: { pathname: value } }));
          } else if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
    if (soc) {
      dispatch(submitFormRequest({ value: 'soc', delay }, stDraft?.states?.loading ? id : undefined));
    }
  };

  useEffect(() => {
    const handleUnclick = (e: MouseEvent) => {
      if (activeState === 'disabled' || activeState === 'loading') return;
  
      if (selectRef.current && !selectRef.current.contains(e.target as Node)) {
        if (outclickUpdates) {
          const styleUpdates = outclickUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
          const requestUpdates = outclickUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
          const functionUpdates = outclickUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
  
          if (styleUpdates.length) {
            dispatch(mergeStyleRequest(styleUpdates, parentIndex));
          }
  
          if (requestUpdates.length) {
            requestUpdates.forEach((update) => {
              const { method, value, silent, config } = update?.request || {};
              const { delay } = config || {};
              if (update?.request && method === 'action') {
                dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
              } else if (update?.request && method === 'open-url') {
                handleOpenUrl(value, delay, silent);
              }
            });
          }
  
          if (functionUpdates.length) {
            functionUpdates.forEach((update) => {
              if (update?.function) {
                executeFunction(update.function, undefined, internalFns);
              }
            });
          }
        }
      }
    };
  
    if (outclickUpdates) {
      document.addEventListener('mousedown', handleUnclick);
      return () => {
        document.removeEventListener('mousedown', handleUnclick);
      };
    }
    return undefined;
  }, [parentIndex, activeState, selectRef.current]);

  const handleSelect = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    handleChange(e, id);
    setIsOpen(false);
  };

  const handleDeleteChip = (chip: string, e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setInternalValue(prevValues => prevValues.filter(value => value !== chip));
  }

  const filteredOptions = Array.isArray(options) 
  ? options.filter(option => {
      if (!isSearchEnabled || !searchTerm) {
        return true;
      }
      if (Array.isArray(option.value)) {
        return option.value.some(val => val.toLowerCase().includes(searchTerm.toLowerCase()));
      }
      return option.value.toLowerCase().includes(searchTerm?.toLowerCase());
    })
  : [];

  const SelectInputComponent = () => (
    <SelectInput
      $shape={{ font }}
      value={searchTerm}
      onChange={e => setSearchTerm(e.target.value)}
      autoFocus
    />
  );

  const getTitleFromSelectOptions = (value: string[] | string) => {
    const parsedVaue = Array.isArray(value) ? value[0] : value;
    const selectedOption = options.find(option => option.id === parsedVaue);
    return selectedOption?.value;
  }

  const PlaceholderSpan = () => {
    return (
      <span style={generateComponentStyles({ font: { ...placeholderStyle, ...(isHovered ? style.states?.hover?.placeholder : {}) } })}>
        {placeholder}
      </span>
    );
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    setIsOpen(!isOpen);
  };

  const ChevronDownComponent = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 20, minWidth: 20 }}>
      <ChevronDown />
    </div>
  );
  return (
    <SelectWrapper
      id={`${id}${(nestedTable && typeof parentIndex === 'number') ? `::${parentIndex}` : ''}`}
      $shape={{ grid: gridStyle, width, height, flex: { grow, shrink, basis } }}
      className={combineClassNames(style.class)}
      data-component="select"
      ref={selectRef}
      role="combobox"
      aria-haspopup="listbox"
      aria-expanded={isOpen}
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
    >
      <SelectButton
        $shape={{ ...otherStyle, border, font }}
        $baseScreenResolution={baseScreenResolution}
        $skipStates={activeState === 'disabled' || activeState === 'loading'}
        onClick={handleClick}
        aria-haspopup="true"
        isOpen={isOpen}
      >
        {isOpen && isSearchEnabled && !isMultiple && SelectInputComponent()}
        {isMultiple && isSearchEnabled && (
          <div className={styles.selectItemsContainer}>
            {Array.isArray(internalValue) && internalValue?.length ? internalValue?.map((val) => {
              const selectedOption = options.find(option => option.id === val);
              return (
                <div key={val} className={styles.selectItem}>
                  <span className={styles.chipItem}>{selectedOption?.value}</span>
                  <div
                    className={styles.chipIcon}
                    role="button"
                    onClick={(e) => handleDeleteChip(val, e)}
                  >
                    <IconSvg />
                  </div>
                </div>
              );
            }) : null}
            {isOpen ? SelectInputComponent() : null}
            {(!isOpen && !internalValue?.length) ? PlaceholderSpan() : null}
          </div>
        )}
        {isMultiple && !isSearchEnabled && (
          <div className={styles.selectItemsContainer}>
            {Array.isArray(internalValue) && internalValue?.length ? internalValue?.map((val) => {
              const selectedOption = options.find(option => option.id === val);
              return (
                <div key={val} className={styles.selectItem}>
                  <span className={styles.chipItem}>{selectedOption?.value}</span>
                  <div
                    className={styles.chipIcon}
                    role="button"
                    onClick={(e) => handleDeleteChip(val, e)}
                  >
                    <IconSvg />
                  </div>
                </div>
              );
            }) : PlaceholderSpan()}
          </div>
        )}
        {((!isOpen && !isMultiple) || (isOpen && !isSearchEnabled && !isMultiple)) && (
          <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {internalValue?.length ? (
              <span>{getTitleFromSelectOptions(internalValue)}</span>
            ) : PlaceholderSpan()}
          </span>
        )}
        {((!isMultiple && !isSearchEnabled) || (!internalValue?.length && !isOpen)) ? ChevronDownComponent() : <div />}
      </SelectButton>
      {isOpen && (
        createPortal(
          <SelectOptions
            $shape={{ border: border }}
            ref={dropdownRef}
            role="listbox"
            height={position.height}
            top={position.top}
            left={position.left}
            width={position.width}
          >
            {filteredOptions.map((option) => (
              <SelectOption
                key={option.id}
                $shape={{ ...otherStyle }}
                onClick={(e) => handleSelect(e, option.id)}
                aria-selected={internalValue.includes(option.id)}
                isSelected={internalValue.includes(option.id)}
                role="option"
              >
                <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{option.value}</span>
                {internalValue.includes(option.id) ?
                  (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: 20,
                        minWidth: 20
                      }}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="check">
                          <path id="Icon" d="M16.6668 5L7.50016 14.1667L3.3335 10" stroke={border?.color || "#7F56D9"} stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                      </svg>
                    </div>
                  ) : null}
              </SelectOption>
            ))}
          </SelectOptions>, document.body
        )
      )}
    </SelectWrapper>
  );
};

export default CombinedSelect;
