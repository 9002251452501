.cancelBtn {
  margin: 9px 0 10px 0;
  border-radius: var(--radius-md, 4px);
  height: 40px;
  box-sizing: border-box;
  border: 1px solid  #D0D5DD;
  background: #FFF;
  padding: 6px 20px !important;
  cursor: pointer;

  /* Shadows/shadow-xs */

  color:  #344054;
  /* Text sm/Semibold */
  font-family: Inter, sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px; /* 142.857% */
  
}

.applyBtn {
  margin: 9px 16px 10px 12px;
  border-radius: var(--radius-md, 4px);
  border: 1px solid #BE2987;
  padding: 6px 20px !important;
  background:  #BE2987;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;

  color:  #FFF;
/* Text sm/Semibold */
  /* Text sm/Semibold */
  font-family: Inter, sans-serif;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px; /* 142.857% */
}