export interface PageProps {
  page: object;
  };

export enum PageActionTypes {
  LOCATION_CHANGE = '@@router/LOCATION_CHANGE',
  PAGE_REQUEST = '@@router/PAGE_REQUEST',
  PAGE_SUCCESS = '@@page/PAGE_SUCCESS',
  PAGE_ERROR = '@@page/PAGE_ERROR',
  CLEAR_FORMS = '@@page/CLEAR_FORMS',
  UPDATE_ITEM_REQUEST = '@@page/UPDATE_ITEM_REQUEST',
  UPDATE_ITEM_SUCCESS = '@@page/UPDATE_ITEM_SUCCESS',
  SUBMIT_FORM_REQUEST = '@@page/SUBMIT_FORM_REQUEST',
  SUBMIT_FORM_SUCCESS = '@@page/SUBMIT_FORM_SUCCESS',
  UPLOAD_FILE_REQUEST = '@@page/UPLOAD_FILE_REQUEST',
  SET_FORM_VALUES_REQUEST = '@@page/SET_FORM_VALUES_REQUEST',
  SET_FORM_VALUES_SUCCESS = '@@page/SET_FORM_VALUES_SUCCESS',
  RESET_FORM_VALUES = '@@page/RESET_FORM_VALUES',
  RESET_FORM_VALUES_SUCCESS = '@@page/RESET_FORM_VALUES_SUCCESS',
  MERGE_STYLE_REQUEST = '@@page/MERGE_STYLE_REQUEST',
  MERGE_STYLE_SUCCESS = '@@page/MERGE_STYLE_SUCCESS',
  UPDATE_NODE_ACTIVE_STATE_REQUEST = '@@page/UPDATE_NODE_ACTIVE_STATE_REQUEST',
  UPDATE_NODE_ACTIVE_STATE_SUCCESS = '@@page/UPDATE_NODE_ACTIVE_STATE_SUCCESS',

  UPDATE_VARIABLE_REQUEST = '@@page/UPDATE_VARIABLE_REQUEST',
  UPDATE_VARIABLE_SUCCESS = '@@page/UPDATE_VARIABLE_SUCCESS',
}

export interface PageState {
  readonly loading: boolean;
  readonly sessionData?: {
    readonly deviceId?: string;
    readonly sessionId?: string;
  };
  readonly data: PageProps;
  readonly styleChunks?: {
    [key: string]: {
      [index: number]: any;
    };
  }
  readonly settings?: {
    readonly variables?: object;
    readonly cols?: string | number | null;
    readonly rows?: string | number | null;
    readonly script?: string;
    readonly fonts?: string;
    readonly page?: {
      readonly title?: string | null;
      readonly icon?: string | null;
    };
  };
  readonly variableData: object;
  readonly formValues: object;
}
