/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import Container from '../../components/Container';
import generateComponentStyles from '../../utils/styleHelper';
import { generateCssRule, filterDisplayProperty, replacePlaceholdersWithVariables } from '../../utils/base';
import parseJson from '../../utils/json';
import { ApplicationState } from '../../store';
import Checkbox from '../../components/Checkbox';
import Image from '../../components/Image';
import Input from '../../components/Input';
import Radio from '../../components/Radio';
import Select from '../../components/Select';
import Separator from '../../components/Separator';
import Switcher from '../../components/Switcher';
import Text from '../../components/Text';

import * as styles from './testComponent.module.scss';
import { GridContainer, NodeTypeScheme } from '../../types/base';
import { rowSizeGlobal, colCountGlobal } from '../../config/config';
import { pageSuccess } from '../../store/page/actions';

const styleElementId = 'custom-css-header-kdlshHhgjhgf';

const componentMapping = {
  checkbox: Checkbox,
  image: Image,
  input: Input,
  radio: Radio,
  select: Select,
  separator: Separator,
  switcher: Switcher,
  text: Text,
};

const InternalFrame: React.FC = () => {
  const [styleApplied, setStyleApplied] = useState(false);
  const config = useSelector((state: ApplicationState) => state.config);
  const page = useSelector((state: ApplicationState) => state.page);
  const { data } = config || {};
  const { editor: editorUrl } = data?.urls || {};
  const { data: pageData2, settings, styleChunks } = page || {};
  const pageData = replacePlaceholdersWithVariables(pageData2, settings?.variables);
  const { page: pp } = pageData || {};
  const dispatch = useDispatch();

  const [output, setOutput] = useState<GridContainer>(null);
  const [eventCopy, setLastEventCopy] = useState<MessageEvent>(null);
  const dispatchContentUpdateEvent = (height: number) => {
    if (eventCopy) {
      const sourceWindow = eventCopy.source as Window;
      sourceWindow.postMessage({ newHeight: height }, eventCopy.origin);
    }
  };

  // Corrected syntax for the type guard function
  const expectedOrigin = editorUrl;

  useEffect(() => {
    setTimeout(() => {
      // const bodyScrollHeight = document.body.scrollHeight;
      const elementId = '6201:298088';
      const element = document.getElementById(elementId);
      if (element) {
        const { scrollHeight } = element;
        dispatchContentUpdateEvent(scrollHeight); // Trigger the event
      }
    }, 1000);
  }, [output]);

  useEffect(() => {
    const receiveMessageFromParent = (event: MessageEvent) => {
      // Check the origin to ensure the message is from the expected source
      if (event.origin === expectedOrigin) {
        if (event.source) {
          const result = parseJson(event.data);
          const sourceWindow = event.source as Window;
          if (result.success && (result as any)?.data?.page) {
            const responseMessage = 'Parsed JSON';
            setLastEventCopy(event);
            setOutput((result as any)?.data?.page as GridContainer);
            dispatch(pageSuccess({ data: (result as any).data.page, settings: (result as any).data.settings }));
            sourceWindow.postMessage(responseMessage, event.origin);
          } else {
            const responseMessage = 'Failed to parse JSON';
            console.log('failed');
            console.log(event.origin);
            sourceWindow.postMessage(responseMessage, event.origin);
          }
        }
      }
    };

    // Attach the receiveMessageFromParent function to the message event
    window.addEventListener('message', receiveMessageFromParent);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('message', receiveMessageFromParent);
    };
  }, [expectedOrigin]);

  useEffect(() => {
    const { basic, variables, classes } = data || {};

    if (basic && variables && classes) {
      const classKeys = Object.keys(classes);
      let styleElement = document.getElementById(styleElementId);
      if (!styleElement) {
        styleElement = document.createElement('style');
        styleElement.id = styleElementId;
        document.head.appendChild(styleElement);
      }
      const resulting = {};
      classKeys.forEach((k) => {
        const classStyles = filterDisplayProperty(classes[k]);
        resulting[k] = generateComponentStyles(classStyles);
      });
      const cssRules = Object.keys(resulting)
        .map((className) => generateCssRule(className, resulting[className]))
        .join('\n\n');
      styleElement.innerText = `${variables}\n${basic}\n${cssRules}`;
    }
  }, [data]);

  useEffect(() => {
    // Notify parent window that iframe is ready to receive messages
    if (window.parent && expectedOrigin) {
      window.parent.postMessage({ type: 'iframeReady' }, expectedOrigin);
    }
  }, [expectedOrigin]);

  return (
    <div>
      <div>
        <div
        className={styles.frameLayout}
        >
          <div
            style={{
              boxSizing: 'border-box',
            }}
            className={cn({
              [styles.full]: true,
            })}
          >
            <div style={{ overflow: 'auto', height: '100%' }}>
              {pp ? (
                <div className={styles.page}>
                  <div className={styles.pageWrap}
                    style={{
                      display: 'grid',
                      gridTemplateColumns: `repeat(${settings?.cols || colCountGlobal}, 1fr)`,
                      gridAutoRows: settings?.rows ? undefined : `${rowSizeGlobal}px`,
                      gridTemplateRows: settings?.rows ? `repeat(${settings?.rows}, 1fr)` : undefined,
                    }}
                  >
                    {typeof pp === 'object' && Object.keys(pp) && !Array.isArray(pp) ? (
                      <Container 
                       {...pp as NodeTypeScheme} 
                       pageSettings={settings} 
                       styleChunks={styleChunks} 
                       />
                    ) : null}

                    {Array.isArray(pp) ? (
                      <>
                        {pp.map((item) => {
                          const Component = componentMapping[item.component];
                          if (Component) {
                            return <Component key={item.id} {...item} pageSettings={settings} styleChunks={styleChunks} />;
                          } else if (item.component === 'container' || item.component === 'table') {
                            return <Container key={item.id} {...(item as NodeTypeScheme)} pageSettings={settings} styleChunks={styleChunks} />;
                          }
                          return null;
                        })}
                      </>
                    ) : null}
                  </div>
                </div>
              ) : <div>No valid data</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalFrame;
