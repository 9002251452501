.page {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: relative;
  flex-direction: column;
}

.frameLayout {
  display: flex;
  position: relative;
  height: 100vh;
}

.pageWrap {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.divider {
  cursor: col-resize;
  width: 4px;
  height: 100%;
  background-color: #ddd;
}

.hidden {
  width: 0;
  overflow: hidden;
  display: none;
}

.full {
  width: 100%;
  height: 100%;
}

.centerButton {
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
}
