import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import merge from 'lodash.merge';
import { selectVariableData } from '../../store/page/selectors';
import { combineClassNames, debounce, replacePlaceholdersWithVariablesV2, handleOpenUrl, getSizesBasedOnScreen, executeFunction, getNestedValue} from '../../utils/base';
import { StyleUpdateChunk, RequestUpdateChunk, FunctionUpdateChunk, Breakpoints } from '../../types/base';
import { pageRequest, submitFormRequest, mergeStyleRequest, formValuesRequest, updateVariableRequest } from '../../store/page/actions';
import { CheckboxComponent as Type } from '../../types/base';
import generateComponentStyles from '../../utils/styleHelper';

const StyledCheckboxLabel = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    jsutify-content: center;
    position: relative;
    z-index: 1;
  ${(props) => generateComponentStyles(props.$shape, props.$baseScreenResolution, props.$skipStates)}
`;

const StyledCheckbox = styled.input`
  cursor: pointer;
  margin: 0;
  pading: 0;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;


interface CheckboxType extends Type {
  dataLake?: any;
  styleChunks?: any;
  nestedTable?: boolean;
  parentIndex?: number;
}

const Checkbox: React.FC<CheckboxType & { title: string, pageSettings?: any }> = ({ id, style: stDraft, value, events: eventsDraft, soc, pageSettings, dataLake, activeState, nestedTable, parentIndex, styleChunks, settings }) => {
  const containerRef = useRef<HTMLLabelElement>(null);
  const variableData = useSelector(selectVariableData);
  const { style: styleDraft, events } = dataLake?.data ? replacePlaceholdersWithVariablesV2({ style: stDraft, events: eventsDraft }, dataLake.data) : { style: stDraft, events: eventsDraft };
  const mergeStylesWithChunks = useCallback((style: any, chunks: any, index: number) => {
    if (chunks && chunks[id] && chunks[id][index] && typeof index === 'number') {
      return merge({}, style, chunks[id][index]);
    }
    return style;
  }, [id]);
  // First useMemo to merge active state styles with base style
  const mergedStyleWithState = useMemo(() => {
    let mergedStyle = styleDraft;
    if (activeState && styleDraft?.states?.[activeState]) {
      mergedStyle = merge({}, styleDraft, styleDraft.states[activeState]);
    }

    // Handle states in breakpoints
    if (styleDraft?.breakpoints) {
      mergedStyle.breakpoints = Object.entries(styleDraft.breakpoints).reduce((acc, [breakpoint, breakpointStyle]) => {
        const typedBreakpointStyle = breakpointStyle as Breakpoints;
        if (typedBreakpointStyle?.states?.[activeState]) {
          acc[breakpoint] = merge({}, typedBreakpointStyle, typedBreakpointStyle.states[activeState]);
        } else {
          acc[breakpoint] = typedBreakpointStyle;
        }
        return acc;
      }, {} as typeof styleDraft.breakpoints);
    }

    return mergedStyle;
  }, [styleDraft, activeState]);

  // Second useMemo to merge styles with chunks based on parentIndex
  const style = useMemo(() => {
    return mergeStylesWithChunks(mergedStyleWithState, styleChunks, parentIndex);
  }, [mergedStyleWithState, styleChunks, parentIndex]);
  const [isChecked, setIsChecked] = useState(value === 'true');
  const baseScreenResolution =  pageSettings?.baseScreenResolution ? getSizesBasedOnScreen(pageSettings?.baseScreenResolution) : undefined;

  const debouncedSubmitForm = useCallback(
    debounce(() => {
      dispatch(submitFormRequest({ value: 'soc' }));
    }, 500),
    []
  );

  useEffect(() => {
    setIsChecked(value === 'true');
  }, [value]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    dispatch(formValuesRequest({ field: { id, value: !isChecked } }));
    if (soc) debouncedSubmitForm(!isChecked);
  };

  const { 
    on_change: clickUpdates, 
    on_launch: launchUpdates, 
    on_hover: hoverUpdates, 
    on_unhover: unhoverUpdates,
    on_outclick: outclickUpdates,
  } = events || {};

  const dispatch = useDispatch();
  const timeoutsRef = useRef<NodeJS.Timeout[]>([]);

  const internalFns = {
    $setVariable: (key: string, value: any) => {
      dispatch(updateVariableRequest({ variable: key, value }));
    },
    $getVariable: (key: string) => {
      return getNestedValue(variableData, key);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    e.preventDefault();
    handleCheckboxChange();
    if (clickUpdates) {
      e.stopPropagation();
  
      const styleUpdates = clickUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = clickUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = clickUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'command') {
            dispatch(pageRequest({ location: { pathname: value } }));
          } else if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  const handleHover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (hoverUpdates) {
      const styleUpdates = hoverUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = hoverUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = hoverUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  
  }

  const handleUnhover = (e: React.MouseEvent<HTMLDivElement>) => {
    if (activeState === 'disabled' || activeState === 'loading') {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (unhoverUpdates) {
      const styleUpdates = unhoverUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = unhoverUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = unhoverUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }
      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, e, internalFns);
          }
        });
      }
    }
  }

  useEffect(() => {
    if (launchUpdates && launchUpdates.length) {
      const styleUpdates = launchUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
      const requestUpdates = launchUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
      const functionUpdates = launchUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
      if (styleUpdates.length) {
        dispatch(mergeStyleRequest(styleUpdates));
      }
      if (requestUpdates.length) {
        requestUpdates.forEach((update) => {
          const { method, value, silent, config } = update?.request || {};
          const { delay } = config || {};
          if (update?.request && method === 'action') {
            // const newTimeout = setTimeout(() => {
            //   dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
            // }, config?.delay * 1000 || 0);

            // timeoutsRef.current.push(newTimeout);
          } else if (update?.request && method === 'open-url') {
            handleOpenUrl(value, delay, silent);
          }
        });
      }

      if (functionUpdates.length) {
        functionUpdates.forEach((update) => {
          if (update?.function) {
            executeFunction(update.function, undefined, internalFns);
          }
        });
      }
    }

    return () => {
      timeoutsRef?.current?.forEach((timeout) => {
        clearTimeout(timeout);
      });
    };
  }, [launchUpdates]);

  useEffect(() => {
    const handleUnclick = (e: MouseEvent) => {
      if (activeState === 'disabled' || activeState === 'loading') return;
  
      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        if (outclickUpdates) {
          const styleUpdates = outclickUpdates.filter((update): update is StyleUpdateChunk => 'style' in update);
          const requestUpdates = outclickUpdates.filter((update): update is RequestUpdateChunk => 'request' in update);
          const functionUpdates = outclickUpdates.filter((update): update is FunctionUpdateChunk => 'function' in update);
  
          if (styleUpdates.length) {
            dispatch(mergeStyleRequest(styleUpdates, parentIndex));
          }
  
          if (requestUpdates.length) {
            requestUpdates.forEach((update) => {
              const { method, value, silent, config } = update?.request || {};
              const { delay } = config || {};
              if (update?.request && method === 'action') {
                dispatch(submitFormRequest({ value, delay }, stDraft?.states?.loading ? id : undefined));
              } else if (update?.request && method === 'open-url') {
                handleOpenUrl(value, delay, silent);
              }
            });
          }
  
          if (functionUpdates.length) {
            functionUpdates.forEach((update) => {
              if (update?.function) {
                executeFunction(update.function, undefined, internalFns);
              }
            });
          }
        }
      }
    };
  
    if (outclickUpdates) {
      document.addEventListener('mousedown', handleUnclick);
      return () => {
        document.removeEventListener('mousedown', handleUnclick);
      };
    }
    return undefined;
  }, [parentIndex, activeState, containerRef.current]);

  return (
    <StyledCheckboxLabel
      ref={containerRef}
      htmlFor={`${id}${(nestedTable && typeof parentIndex === 'number') ? `::${parentIndex}` : ''}`}
      $shape={style}
      $baseScreenResolution={baseScreenResolution}
      $skipStates={activeState === 'disabled' || activeState === 'loading'}
      data-component="checkbox"
      className={combineClassNames(style.class)}
      onClick={handleClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
    >
      <StyledCheckbox
        id={`${id}${(nestedTable && typeof parentIndex === 'number') ? `::${parentIndex}` : ''}`}
        type="checkbox"
      />
          {isChecked
            ? (settings?.icons?.active ? <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={settings.icons.active} />  : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'scale(0.9)', overflow: 'visible' }}>
              <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H12C13.933 0.5 15.5 2.067 15.5 4V12C15.5 13.933 13.933 15.5 12 15.5H4C2.067 15.5 0.5 13.933 0.5 12V4Z" stroke={style?.font?.color || 'white'} />
              <path d="M12 5L6.5 10.5L4 8" stroke={style?.font?.color || 'white'} strokeWidth="1.6666" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>)
            : (settings?.icons?.inactive ? <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={settings.icons.inactive} /> : <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'scale(0.9)', overflow: 'visible' }}>
              <path d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H12C13.933 0.5 15.5 2.067 15.5 4V12C15.5 13.933 13.933 15.5 12 15.5H4C2.067 15.5 0.5 13.933 0.5 12V4Z" stroke={style?.font?.color || 'white'} />
            </svg>)
          }
    </StyledCheckboxLabel>
  );
}

export default Checkbox;
